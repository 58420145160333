<template>
  <v-card>
    <div class="pt-4 pl-4">
      <v-checkbox
        :label="$t('accounting.chart_action.show_total_amount')"
        dense
        v-model="showTotalAmount"
      ></v-checkbox>
    </div>

    <line-chart
      v-if="chartReady"
      :style="style"
      :chartData="chartData"
      :options="options"
    ></line-chart>
  </v-card>
</template>

<script lang="babel" type="text/babel">
import lineChart from 'components/charts/lineChart.vue'
export default {
  props: {
    logs: Array,
    dateRangeStart: String,
    dateRangeEnd: String,
  },
  data: () => ({
    height: 300,
    showTotalAmount: false,
  }),
  methods: {
    getLogDailyData(date, property) {
      const dailyData = this.logs.find(log => log.date === date)
      if(!dailyData) return 0
      return dailyData[property] || 0
    },
    getAmountDailyArray(property) {
      let logs = []
      for(const date of this.dates) {
        logs.push(this.getLogDailyData(date, property))
      }
      return logs
    },
  },
  computed: {
    dates() {
      if(!this.dateRangeStart || !this.dateRangeEnd) return null
      if(this.dateRangeStart === this.dateRangeEnd) return [this.dateRangeStart]
      let result = []
      let date = this.dateRangeStart
      while (date <= this.dateRangeEnd) {
        result.push(date)
        date = this.$dayjs(date).add(1, 'days').format('YYYY-MM-DD')
      }
      return result
    },
    chartData() {
      let totalAmountData = [{
          label: '總金額',
          borderColor: '#b3b3b3',
          fill: false,
          data: this.getAmountDailyArray('total_amount'),
      }]
      if(!this.showTotalAmount) totalAmountData = []

      return {
        labels: this.dates,
        datasets: [
          ...totalAmountData,
          {
            label: '收入',
            borderColor: '#38c172',
            fill: false,
            data: this.getAmountDailyArray('total_revenue_amount'),
          },
          {
            label: '支出',
            borderColor: '#f66d9b',
            fill: false,
            data: this.getAmountDailyArray('total_cost_amount'),
          },
          {
            label: '沖銷',
            borderColor: '#6cb2eb',
            fill: false,
            data: this.getAmountDailyArray('total_offset_amount'),
          },
        ]
      }
    },
    chartReady() {
      if(!this.dates) return false
      return this.logs.length > 0
    },
    style() {
      return {
        height: `${this.height}px`,
      }
    },
    options() {
      return {
        responsive: true,
        maintainAspectRatio: false
      }
    },
  },
  components: {
    lineChart,
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
