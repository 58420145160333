import { render, staticRenderFns } from "./accountingCharts.vue?vue&type=template&id=5b2bb20b&scoped=true&"
import script from "./accountingCharts.vue?vue&type=script&lang=babel&"
export * from "./accountingCharts.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b2bb20b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
installComponents(component, {VCard,VCheckbox})
